import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { RippleEffect } from '../ripple/ripple'

export const Preview = () => {
  const { asPath } = useRouter()
  const [isPreview, setIsPreview] = useState(false)

  useEffect(() => {
    const data = async () => {
      const fetchedData = await fetch('/api/auth/preview').then((r) => r.json())
      setIsPreview(fetchedData.preview === true)
    }
    data()
  }, [asPath])

  const onLogout = useCallback(() => {
    const data = async () => {
      const fetchedData = await fetch('/api/auth/logout').then((r) => r.json())
      if (fetchedData.ok) {
        setIsPreview(false)
      }
    }
    data()
  }, [])
  return isPreview && asPath !== '/preview' ? (
    <div className="absolute p-1 md:p-2 z-[100] flex gap-4 items-center w-full bg-primary-200/60 touch-none pointer-events-none">
      <div>Preview</div>

      <button onClick={onLogout} className="touch-auto pointer-events-auto">
        <RippleEffect className="py-1 px-2 md:py-2 md:px-4 border rounded border-mono-600">
          Logout
        </RippleEffect>
      </button>
    </div>
  ) : null
}
