import { DetailedHTMLProps, forwardRef, HTMLAttributes, useRef } from 'react'
import { animated, useSpring } from 'react-spring'

export const RippleEffect = forwardRef<
  HTMLDivElement,
  { relative?: boolean; absolute?: boolean } & DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
>(({ relative = true, absolute, children, ...rest }, ref) => {
  const ripple = useRef<HTMLDivElement | null>(null)

  const rippleValues = {
    from: { scale: 0, opacity: 0.3, visible: 0 },
    to: { scale: 1.5, opacity: 0.3, visible: 1 },
    immediate: false,
    config: { duration: 250 },
  }

  const [spring, setSpring] = useSpring(() => {
    return {
      ...rippleValues,
      immediate: true,
      onStart: () => {
        if (!ripple.current) {
          return
        }
        const wrapper = ripple.current.parentElement
        const rippleContainer = ripple.current

        if (!wrapper) {
          return
        }
        const size =
          wrapper.offsetWidth > wrapper.offsetHeight
            ? wrapper.offsetWidth
            : wrapper.offsetHeight

        rippleContainer.style.width = rippleContainer.style.height = `${size}px`
      },
    }
  })

  return (
    <div
      {...rest}
      className={`${relative && !absolute ? 'relative' : 'absolute'} ${
        rest.className || ''
      }`}
      onClick={(e) => {
        setSpring.start({ ...rippleValues, immediate: false })
        rest.onClick && rest.onClick(e)
      }}
      ref={ref}
    >
      {children}
      <div className="absolute w-full h-full left-0 top-0 overflow-hidden flex justify-center items-center">
        <animated.div
          className="bg-bg-900 w-full pointer-events-none z-50 rounded-full opacity-30"
          style={{
            zIndex: 1000,
            display: spring.visible.to((v) => {
              return v === 1 ? 'none' : 'block'
            }),
            opacity: spring.opacity.to((o) => o),
            transform: spring.scale.to((s) => `scale(${s})`),
          }}
          ref={ripple}
        />
      </div>
    </div>
  )
})
