import { AppProps } from 'next/app'
import Head from 'next/head'
import '../styles/globals.css'
import '../styles/ripple.css'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { Preview } from '../components/preview/preview'

export default function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TK2HMZB' })
  }, [])
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <title>Kunsthaus Zürich || Floorplan</title>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="author" content="dreipol GmbH" />
        <meta name="description" content="Kunsthaus Zürich Floorplan" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Floorplan" />
        <link
          rel="apple-touch-icon"
          href="assets/icons/apple-icon-180x180.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/icons/apple-icons/apple-icon-57x57.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/icons/apple-icons/apple-icon-60x60.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/icons/apple-icons/apple-icon-72x72.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/icons/apple-icons/apple-icon-76x76.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/icons/apple-icons/apple-icon-114x114.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/icons/apple-icons/apple-icon-120x120.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/icons/apple-icons/apple-icon-144x144.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icons/apple-icons/apple-icon-152x152.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-icons/apple-icon-180x180.jpg"
        />
        <link
          href="/splashscreens/iphone5_splash.png"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphone6_splash.png"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphoneplus_splash.png"
          media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/iphonex_splash.png"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipad_splash.png"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro1_splash.png"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/splashscreens/ipadpro2_splash.png"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
          rel="apple-touch-startup-image"
        />
        <link
          href="/icons/favicon-192x192.png"
          rel="icon"
          type="image/png"
          sizes="192x192"
        />
        <link
          href="/icons/favicon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link
          href="/icons/favicon-96x96.png"
          rel="icon"
          type="image/png"
          sizes="96x96"
        />
        <link
          href="/icons/favicon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <meta name="msapplication-TileColor" content="#1C1C1E" />
        <meta
          name="msapplication-TileImage"
          content="/icons/icon-144x144.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="application-name" content="Floorplan" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#1C1C1E" />
        <meta name="keywords" content="Keywords" />
      </Head>
      <Preview />
      <Component {...pageProps} />
    </>
  )
}
